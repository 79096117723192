<template>
  <div class="container-flued">

  <div class="row">
    <h1>Синонимы</h1>
  </div>

  <hr>
  <form class="row">
    <h6 class="">Добавление синонимов</h6>
    <div class="">

      <div v-for="(item, index) in form" class="row">
        <div class="col-3">
          <div>
            <label class="" for="inlineFormInputGroupManufacturerName">Слово</label>
            <input v-model="item.word" type="text" class="form-control" id="inlineFormInputGroupManufacturerName" placeholder="">
          </div>
          <button @click="addWord" type="button" class="btn btn-secondary mt-3">+</button>
        </div>


        <div class="col-9">
          <label class="" for="inlineFormInputGroupManufacturerName">Искать как:</label>
          <div class="" style="">
            <div v-for="(i, j) in item.word_array" class="col-3 mb-3">
            <input v-model="item.word_array[j].value" type="text" class="form-control" id="" placeholder="">
            </div>
          </div>
          <button @click="addItem(index)" type="button" class="btn btn-secondary">+</button>
        </div>
      </div>

    </div>

    <div class="col-2">
      <button @click="store" type="button" class="btn btn-primary">Добавить</button>
    </div>
  </form>
  <hr>

  <div class="row">
    <div class="col-4">
      <table class="table caption-top table-bordered">
        <caption>Свойство</caption>
        <thead>
        <tr>
          <th scope="col">Слово</th>
          <th scope="col">Искать как</th>
          <th scope="col">Удалить</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in synonyms">
          <td>{{ index }}</td>
          <td>
            <div>
              <span v-for="(i, j) in item" @click="destroy(index, j)">{{ i + (item.length > 1 ? ', ' : '')}}</span>
            </div>
          </td>
          <td>
            <button type="button" class="delete-btn" @click="destroy(index)"></button></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  </div>

</template>

<script>
export default {
  data(){
    return {
      form: [
        {
          word: '',
          word_array: [
            {
              prop: '',
              value: ''
            }
          ]
        }
      ]
    }
  },
  computed: {
    synonyms(){
      return this.$store.getters['search/items']
    }
  },
  methods: {
    addWord(){
      let obj = {
        word: '',
        word_array: [
          {
            prop: '',
            value: ''
          }
        ]
      }
      this.form.push(obj)
    },
    addItem(index){
      let obj = {
        prop: '',
        value: ''
      }
      this.form[index].word_array.push(obj)
    },
    async store(){
      await this.$store.dispatch('search/store', this.form)
    },
    destroy(index, j){
      this.$store.dispatch('search/destroy', {i: index, j: j})

    },
  },
  async created() {
    await this.$store.dispatch('search/items')
  }
}
</script>

<style scoped>
.delete-btn{
  height: 22px;
  width: 22px;
  mask-image: url(../assets/icon/delete_FILL0_wght400_GRAD0_opsz48.svg);
  mask-size: cover;
  background-color: #5c636a;
}
</style>